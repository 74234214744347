/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Helmet from '../components/Helmet';
import Lottie from '../components/Lottie';
import WebForm from '../components/WebForm';
import { ContactLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import { PIPEDRIVE_CONTACT_FORM_URL } from '../config';
import { FONT_MEDIUM, PRIMARY_COLOR } from '../shared/styles';

export const Head = withI18n(() => {
  return <Helmet />;
});

const GetInTouch = () => {
  const { t } = useTranslation();

  return (
    <ContactLayout>
      <ContactUsWrapper>
        <PageLoader>
          <Lottie style={{ width: '50px', height: '50px' }} />
          <p>{t('loading')}</p>
        </PageLoader>

        <WebForm url={PIPEDRIVE_CONTACT_FORM_URL} />
      </ContactUsWrapper>
    </ContactLayout>
  );
};

const PageLoader = styled.div`
  position: absolute;
  p {
    margin-top: 10px;
    text-align: center;
    ${FONT_MEDIUM}
  }
`;

const ContactUsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 40rem;
  .pipedriveWebForms {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #eae8e8;
    border-left: 7px solid ${PRIMARY_COLOR};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    min-height: 20rem;
    min-width: 100%;
    padding: 2.5rem 1rem;
  }
`;

export default GetInTouch;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
